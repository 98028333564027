import React, { FunctionComponent, useEffect, useState } from "react";

import Header from "./Header/Header";
import Footer from "./Footer/Footer";
import GlossaryPopup from "../GlossaryPopup";
import ChapterTracker from "./ChapterTracker";
import { resetScroll } from "../../helpers/tracker";
import ScrollTracker from "./ScrollTracker";

type LayoutProps = {
  slug?: string;
  childRoutes?: string[];
};
const Layout: FunctionComponent<LayoutProps> = ({
  slug,
  children,
  childRoutes,
}) => {

  useEffect(() => {
    resetScroll();
  }, []);


  return (
    <ScrollTracker>
      <ChapterTracker slug={slug} childRoutes={childRoutes}>
        <Header />
        <main>{children}</main>
        <Footer />
        <GlossaryPopup />
      </ChapterTracker>
    </ScrollTracker>
  );
};
export default Layout;

import React, { FunctionComponent } from "react"

type SeparatorProps = {
  className?: string
}
const Separator: FunctionComponent<SeparatorProps> = ({ className }) => {
  return (
    <div className={[className, "separator"].join(" ")}>

    </div >
  )
}
export default Separator

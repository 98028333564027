import React, { FunctionComponent } from "react";
import Button from "./Button";
import Translation, { useTranslation } from "./Translation";

import tracker, { adapt } from "../helpers/tracker";

type LeavingSiteModalProps = {
  href: string;
  analytics: string;
  onCancel: () => void;
};
const template: FunctionComponent<LeavingSiteModalProps> = ({
  children,
  onCancel,
  href,
  analytics,
}) => {
  const { t } = useTranslation();
  const track = tracker(adapt(analytics));

  return (
    <div
      className="modal fixed w-full h-full top-0 right-0 left-0 bottom-0 bg-black-transparent z-30"
      onClick={onCancel}
    >
      <div className="mx-auto my-40 w-full tablet:w-1/2">
        <div className="modal-bg"></div>
        <div className="close"></div>
        <div className="content text-center bg-white">
          <div className="pt-16 px-12">
            <h2 className="uppercase font-bold">
              <Translation id="you-are-leaving" />
            </h2>
            <p className="text-center">
              <Translation id="you-will-be-directed" />
            </p>
          </div>
          <div className="flex justify-center mx-4 pt-8 pb-16">
            <div className="px-4">
              <a href={href} onClick={track}>
                <Button color="green" size="medium" text={t("continue")} />
              </a>
            </div>
            <div className="px-4">
              <Button
                color="red"
                size="medium"
                text={t("cancel")}
                onClick={onCancel}
                noArrow
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default template;

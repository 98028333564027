import React, { FunctionComponent } from "react";
import Link from "../../Link";
import Translation from "../../Translation";

const Resources: FunctionComponent = () => {
  return (
    <Link href="/resources" className="resources">
      <div className="icon"></div>
      <div className="pl-4 uppercase underline laptop:pl-2 laptop:normal-case laptop:no-underline">
        <Translation id="resources" />
      </div>
    </Link>
  );
};

export default Resources;

import React, { FunctionComponent, useEffect, useState } from "react";

const ScrollTracker: FunctionComponent = ({ children }) => {
  const triggers = [0.5, 0.6, 0.8, 1.0];
  const [maxScroll, setMaxScroll] = useState(0);
  const [lastTrigger, setLastTrigger] = useState(0);

  function fireEvent(percent: number) {
    const data = {
      event: "gild.scroll",
      "gtm.scrollThreshold": Math.floor(percent * 100),
      "gtm.scrollUnits": "percent"
    };
    window.dataLayer.push(data);
  }

  function scrollPercent() {
    var scrollHeight = document.documentElement.scrollHeight;
    var clientHeight = document.documentElement.clientHeight;
    var scrollTop = document.documentElement.scrollTop;
    return (scrollTop + clientHeight) / scrollHeight;
  }

  function onScroll(): void {
    const current = scrollPercent();
    if (current > maxScroll) {
      triggers.reduce((cur, trigger) => {
        if (cur >= trigger) {
          if (trigger > lastTrigger) {
            fireEvent(trigger);
            setLastTrigger(trigger);
          }
        }
        return cur;
      }, current);
      setMaxScroll(current);
    }
  }


  useEffect(() => {
    window.addEventListener("scroll", onScroll);
    onScroll();
    return () => {
      window.removeEventListener("scroll", onScroll);
    };
  }, []);
  return <>{children}</>;
};
export default ScrollTracker;

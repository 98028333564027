import React, { FunctionComponent, useState } from "react";
import { navigate } from "gatsby";
import LeavingSiteModal from "./LeavingSiteModal";
import tracker, { adapt } from "../helpers/tracker";

type LinkProps = {
  className?: string;
  external?: boolean;
  skipModal?: boolean;
  anchor?: boolean;
  target?: string;
  href: string;
  analytics: string;
  download?: boolean;
  onClick?: (e: React.MouseEvent<HTMLElement>) => void;
};
const Link: FunctionComponent<LinkProps> = ({
  anchor = false,
  children,
  external = false,
  skipModal = false,
  href,
  ...props
}) => {
  const track = tracker(adapt(props.analytics));
  const onClick = (e: React.MouseEvent<HTMLAnchorElement>) => {
    track();
    if (props.onClick !== undefined) {
      props.onClick(e);
    } else {
      e.preventDefault();
      navigate(href);

    }
  };
  if (external || anchor || props.download || props.onClick) {
    if (external && !skipModal) {
      return (
        <ExternalLink href={href} {...props}>
          {children}
        </ExternalLink>
      );
    }
    return (
      <a href={href} {...props} onClick={onClick}>
        {children}
      </a>
    );
  }
  return (
    <a href={href} {...props} onClick={onClick}>{children}</a>
  );
};
export default Link;

const ExternalLink: FunctionComponent<LinkProps> = ({
  children,
  href,
  ...props
}) => {
  const [modalOpen, setModalOpen] = useState(false);

  const showModal = (
    event: React.MouseEvent<HTMLAnchorElement, MouseEvent>
  ): void => {
    event.preventDefault();
    setModalOpen(true);
  };
  const hideModal = () => {
    setModalOpen(false);
  };
  return (
    <>
      <a href={href} {...props} onClick={showModal}>
        {children}
      </a>
      {modalOpen && (
        <LeavingSiteModal
          href={href}
          onCancel={hideModal}
          analytics={props.analytics}
        />
      )}
    </>
  );
};

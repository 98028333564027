import { useI18next } from "../components/Translation";

declare global {
  interface Window {
    dataLayer: {
      push: (data: object) => void;
    };
  }
}
export default function tracker(data: object) {
  return () => {
    window.dataLayer.push({ ...data, event: "gild.event" });
  };
}

function adapt(analytics: string) {
  if (analytics && analytics !== "") {
    const { language } = useI18next();
    const [
      eventCategory,
      eventAction,
      eventLabel,
      interaction,
      subject,
      intendedAudience,
    ] = analytics.replace(/'/g, "").split(",");
    return {
      eventCategory,
      eventAction,
      eventLabel,
      interaction: interaction || "Interaction",
      subject: subject || "Disease Information",
      intendedAudience: intendedAudience || "Patient",
      language,
    };
  }
  return {};
}

function resetScroll() {
  window.dataLayer.push({
    event: "gild.scrollReset",
    "gtm.scrollThreshold": 0,
    "gtm.scrollUnits": "percent",
  });
}

function formatTime(sec: number) {
  if (sec < 60) {
    return ":" + sec;
  } else {
    const minutes = Math.floor(sec / 60);
    const seconds = (sec - minutes * 60).toString().padStart(2, "0");
    return minutes + ":" + seconds;
  }
}
export { adapt, resetScroll, formatTime };

import React, { FunctionComponent, useState } from "react";
import Link from "../../Link";
import Translation from "../../Translation";
import Resources from "./Resources";

const Navigation: FunctionComponent = () => {
  return (
    <nav className="shadow-xl laptop:shadow-none">
      <div className="container mx-auto py-2">
        <ul className="level-one flex flex-col laptop:flex-row w-full justify-between">
          <li className="first">
            <Link
              analytics="Internal Links,Clicked,What is Hep B"
              href="/what-is-hep-b"
            >
              <Translation id="what-is-hepb" />
            </Link>
            <div className="sub-nav">
              <div className="sub-nav-inner">
                <ul>
                  <li>
                    <Link
                      analytics="Internal Links,Clicked,What is Hep B: The Virus"
                      href="/what-is-hep-b/the-virus"
                    >
                      <Translation id="the-virus" />
                    </Link>
                  </li>
                  <li>
                    <Link
                      analytics="Internal Links,Clicked,What is Hep B: Chronic Hep B"
                      href="/what-is-hep-b/chronic-hep-b/"
                    >
                      <Translation id="chronic-hepb" />
                    </Link>
                  </li>
                  <li>
                    <Link
                      analytics="Internal Links,Clicked,What is Hep B: Effects on the Liver"
                      href="/what-is-hep-b/effects-on-the-liver/"
                    >
                      <Translation id="effects-on-liver" />
                    </Link>
                  </li>
                  <li>
                    <Link
                      analytics="Internal Links,Clicked,What is Hep B: How the Virus is Spread"
                      href="/what-is-hep-b/how-the-virus-is-spread/"
                    >
                      <Translation id="how-virus-spread" />
                    </Link>
                  </li>
                </ul>
              </div></div>
          </li>
          <li className="hidden laptop:block">
            <div className="separator"></div>
          </li>
          <li>
            <Link
              href="/hep-b-testing-diagnosis/"
              analytics="Internal Links,Clicked, Testing and Diagnosis"
            >
              <Translation id="testing-and-diagnosis" />
            </Link>
            <div className="sub-nav">
              <div className="sub-nav-inner">
                <ul>
                  <li>
                    <Link
                      href="/hep-b-testing-diagnosis/risk-factors/"
                      analytics="Internal Links,Clicked,Testing and Diagnosis: Risk Factors"
                    >
                      <Translation id="risk-factors" />
                    </Link>
                  </li>
                  <li>
                    <Link
                      href="/hep-b-testing-diagnosis/getting-tested/"
                      analytics="Internal Links,Clicked,Testing and Diagnosis: Getting Tested"
                    >
                      <Translation id="getting-tested" />
                    </Link>
                  </li>
                  <li>
                    <Link
                      href="/hep-b-testing-diagnosis/hep-b-test-results/"
                      analytics="Internal Links,Clicked,Testing and Diagnosis: The Results"
                    >
                      <Translation id="the-results" />
                    </Link>
                  </li>
                  <li>
                    <Link
                      href="/hep-b-testing-diagnosis/hep-b-vaccine/"
                      analytics="Internal Links,Clicked,Testing and Diagnosis: Vaccinate"
                    >
                      <Translation id="when-to-vaccinate" />
                    </Link>
                  </li>
                </ul>
              </div></div>
          </li>
          <li className="hidden laptop:block">
            <div className="separator"></div>
          </li>
          <li>
            <Link
              href="/considering-hep-b-treatment/"
              analytics="Internal Links,Clicked,Considering Treatment"
            >
              <Translation id="considering-treatment" />
            </Link>
          </li>
          <li className="hidden laptop:block">
            <div className="separator"></div>
          </li>
          <li className="last">
            <Link
              href="/living-with-hep-b/"
              analytics="Internal Links,Clicked,Living with Hep B"
            >
              <Translation id="living-with-hepb" />
            </Link>
            <div className="sub-nav">
              <div className="sub-nav-inner">
                <ul>
                  <li>
                    <Link
                      href="/living-with-hep-b/ongoing-monitoring/"
                      analytics="Internal Links,Clicked,Living with Hep B: Ongoing Monitoring"
                    >
                      <Translation id="ongoing-monitoring" />
                    </Link>
                  </li>
                  <li>
                    <Link
                      href="/living-with-hep-b/managing-hep-b/"
                      analytics="Internal Links,Clicked,Living with Hep B: Managing Your Condition"
                    >
                      <Translation id="managing-your-condition" />
                    </Link>
                  </li>
                  <li>
                    <Link
                      href="/living-with-hep-b/hep-b-facts/"
                      analytics="Internal Links,Clicked,Living with Hep B: Learn the Truth About Hep B"
                    >
                      <Translation id="learn-the-truth" />
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </li>
          <li className="block laptop:hidden">
            <Resources />
          </li>
        </ul>
      </div>
    </nav>
  );
};
export default Navigation;

import { Link } from "gatsby";
import React, { FunctionComponent } from "react";
import Navigation from "./Navigation";
import GileadFooter from "./GileadFooter";

const Footer: FunctionComponent = () => {
  return (
    <footer className="shadow-footer">
      <Navigation />
      <GileadFooter />
    </footer>
  );
};
export default Footer;

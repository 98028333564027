import { data } from "autoprefixer";
import { graphql, useStaticQuery } from "gatsby";
import React, { FunctionComponent } from "react";
import { Helmet } from "react-helmet";
import { standardLanguageCode } from "../helpers/language";
import { useTranslation, useI18next } from "./Translation";

type MetaProps = {
  name: string;
  content: string;
};
type SEOProps = {
  name: string;
  meta?: MetaProps[];
  overrideTitle?: string;
  overrideDescription?: string;
  overrideTwitter?: string;
  overrideOgTitle?: string;
  overrideOgDescription?: string;
  baseName: string;
};
const SEO: FunctionComponent<SEOProps> = ({
  meta,
  name,
  overrideDescription,
  overrideTitle,
  overrideTwitter,
  overrideOgTitle,
  overrideOgDescription,
  baseName,
}) => {
  const data = useStaticQuery(graphql`
    query {
      logo: file(base: { eq: "hepb.png" }) {
        publicURL
        childImageSharp {
          sizes {
            presentationWidth
            presentationHeight
          }
        }
      }
    }
  `);
  const { language } = useI18next();

  const { t } = useTranslation();
  const title = overrideTitle || t(`${name}-title`);
  const metaDescription = overrideDescription || t(`${name}-description`);
  const twitter = overrideTwitter || t(`${name}-twitter`) || metaDescription;
  const ogTitle = overrideOgTitle || t(`${name}-og-title`) || title;
  const ogDescription =
    overrideOgDescription || t(`${name}-og-description`) || metaDescription;

  const canonical = "https://www.hepb.com/" + baseName;
  return (
    <Helmet
      htmlAttributes={{
        lang: standardLanguageCode(language),
      }}
      title={title}
      meta={[
        {
          name: `description`,
          content: metaDescription,
        },
        {
          property: `og:title`,
          content: ogTitle,
        },
        {
          property: `og:description`,
          content: ogDescription,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          name: `twitter:card`,
          content: `summary`,
        },
        {
          name: `twitter:creator`,
          content: `Gilead`,
        },
        {
          name: `twitter:title`,
          content: title,
        },
        {
          name: `twitter:description`,
          content: twitter,
        },
        { name: `og:image`, content: data.logo.publicURL },
        {
          name: `og:image:width`,
          content: data.logo.childImageSharp.sizes.presentationWidth,
        },
        {
          name: `og:image:height`,
          content: data.logo.childImageSharp.sizes.presentationHeight,
        },
      ].concat(meta || [])}
    >
      <link rel="canonical" href={canonical} />
    </Helmet>
  );
};

export default SEO;

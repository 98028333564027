import React, { FunctionComponent } from "react";
import Translation from "../../Translation";
import Image from "./Image";
import Resources from "./Resources";
import Toggle from "./Toggle";

type ActionsProps = {
  languagesOpen: boolean;
  toggleLanguages: VoidFunction;
};
const template: FunctionComponent<ActionsProps> = ({
  languagesOpen,
  toggleLanguages,
}) => {
  return (
    <>
      <Resources />
      <Toggle name="language" open={languagesOpen} toggle={toggleLanguages}>
        <div className="current">
          <Translation id="language" />
        </div>
      </Toggle>
    </>
  );
};

export default template;

import React, { FunctionComponent } from "react";
import { Link } from "../../../Translation";
import ArrowSrc from "../../../../images/icons/language-arrow.svg";
type LanguageProps = {
  lang: string;
  label: string;
  to: string;
};
const Language: FunctionComponent<LanguageProps> = ({ lang, label, to }) => {
  return (
    <div className="language w-1/2 laptop:w-auto px-4 py-2 laptop:py-0">
      <Link to={to} language={lang}>
        <div className="flex flex-row justify-between uppercase items-center leading-none">
          {label}
          <img
            src={ArrowSrc}
            style={{ width: "10px" }}
            className="laptop:ml-2"
          />
        </div>
      </Link>
    </div>
  );
};

export default Language;

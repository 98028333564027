export const throttle = <TFunc extends (...args: any[]) => any>(
  func: TFunc,
  wait: number,
  callFirst: boolean = false
) => {
  let shouldWait = false
  let callNow = false
  return (...args: Parameters<TFunc>) => {
    callNow = callFirst && !shouldWait
    if (!shouldWait) {
      shouldWait = true
      window.setTimeout(() => {
        shouldWait = false
        if (!callFirst) {
          return func(...args)
        }
      }, wait)
    }
    if (callNow) {
      callNow = false
      return func(...args)
    }
  }
}

import React, { FunctionComponent } from "react";

type ToggleProps = {
  name: string;
  open: boolean;
  toggle: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
};
const Toggle: FunctionComponent<ToggleProps> = ({
  name,
  open,
  toggle,
  children,
}) => {
  return (
    <button
      role="button"
      className={["button", name, open && "open"].filter((x) => x).join(" ")}
      onClick={toggle}
    >
      <div className="icon"></div>
      {children}
    </button>
  );
};

export default Toggle;

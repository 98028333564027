import { isBreakpointOrGreater } from "./breakpoints";

const MOBILE_HEADER_HEIGHT = 75;
const DESKTOP_HEADER_HEIGHT = 110;
const PADDING = 32;

function headerHeight() {
  return isBreakpointOrGreater("laptop")
    ? DESKTOP_HEADER_HEIGHT
    : MOBILE_HEADER_HEIGHT;
}
export default function scrollToElement(id: string): void {
  const element = document.getElementById(id);
  if (element) {
    const position =
      element.getBoundingClientRect().top - PADDING - headerHeight();

    window.scrollTo({ top: position, behavior: "smooth" });
  }
}

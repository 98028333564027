import React, { FunctionComponent } from "react"

type SeparatorProps = {
  className?: string
}
const Separator: FunctionComponent<SeparatorProps> = ({ className }) => {
  return (
    <span className={[className, "separator", "inline-block"].join(" ")}>
      |
    </span>
  )
}
export default Separator

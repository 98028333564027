import { graphql, useStaticQuery } from "gatsby";
import React, { FunctionComponent, useEffect, useState } from "react";
import { throttle } from "../../../helpers/throttle";
import Image from "../../Image";
import Link from "../../Link";
import Translation from "../../Translation";
import Navigation from "./Navigation";
import Languages from "./Languages/Languages";
import Toggle from "./Toggle";
import Resources from "./Resources";
import Actions from "./Actions";

const SCROLLED_THRESHOLD = 50;
const Header: FunctionComponent = () => {
  const data = useStaticQuery(
    graphql`
      query {
        logo: file(relativePath: { eq: "logos/hepb.svg" }) {
          publicURL
        }
      }
    `
  );

  const [hamburgerOpen, setHamburgerOpen] = useState(false);
  const [languagesOpen, setLanguagesOpen] = useState(false);
  const toggleHamburger = () => setHamburgerOpen((open) => !open);
  const toggleLanguages = () => setLanguagesOpen((open) => !open);

  const [scrolled, setScrolled] = useState(false);
  function updateScrolled() {
    setScrolled((scrolled) => {
      if (scrolled) {
        return window.scrollY !== 0;
      }
      return window.scrollY >= SCROLLED_THRESHOLD;
    });
  }
  useEffect(() => {
    const throttledScrollHandler = throttle(updateScrolled, 300);
    window.addEventListener("scroll", throttledScrollHandler);
    updateScrolled();
    return () => {
      window.removeEventListener("scroll", throttledScrollHandler);
    };
  }, []);

  const props = {
    hamburgerOpen,
    languagesOpen,
    toggleHamburger,
    toggleLanguages,
  };
  return (
    <header className={scrolled ? "scrolled" : ""}>
      <Languages open={languagesOpen} toggle={toggleLanguages} />
      <div className="container mx-auto pl-4 tablet:px-4">
        <div className="banner flex justify-between items-center laptop:flex-wrap desktop:flex-nowrap">
          <div className="logo laptop:self-end z-10">
            <Link href="/" analytics="Internal Links, Clicked, Homepage">
              <img src={data.logo.publicURL} className="w-full" />
            </Link>
          </div>
          <div className="wrapper laptop:-mt-20 desktop:mt-0">
            <div className="actions flex justify-end">
              <Actions
                languagesOpen={languagesOpen}
                toggleLanguages={toggleLanguages}
              />
              <div className="block laptop:hidden">
                <Toggle
                  name="hamburger"
                  open={hamburgerOpen}
                  toggle={toggleHamburger}
                />
              </div>
            </div>
            <div
              className={["menu", hamburgerOpen && "open"]
                .filter((x) => x)
                .join(" ")}
            >
              <Navigation />
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;

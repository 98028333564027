import React, { FunctionComponent } from "react";

import ArrowRightLargeInverted from "../images/icons/right-large-inverted.svg";
import ArrowRightLarge from "../images/icons/right-large.svg";
import ArrowRightMediumInverted from "../images/icons/right-medium-inverted.svg";
import ArrowRightMedium from "../images/icons/right-medium.svg";
import ArrowRightSmallInverted from "../images/icons/right-small-inverted.svg";
import ArrowRightSmall from "../images/icons/right-small.svg";
import Link from "./Link";

const SIZES = ["small", "medium", "large"];
const DEFAULT_SIZE = "medium";

const COLORS = ["green", "red", "white", "transparent"];
const DEFAULT_COLOR = "white";

type ButtonProps = {
  text: string;
  size: string;
  color: string;
  submit?: boolean;
  onClick?: VoidFunction;
  className?: string;
  href?: string;
  external?: boolean;
  noArrow?: boolean;
  download?: boolean;
};
const Button: FunctionComponent<ButtonProps> = (props) => {
  function validateSize(size: string): string {
    if (SIZES.includes(size)) {
      return size;
    }
    return DEFAULT_SIZE;
  }
  function validateColor(color: string): string {
    if (COLORS.includes(color)) {
      return color;
    }
    return DEFAULT_COLOR;
  }
  function arrowSrc(size: string, color: string): string {
    switch (size) {
      case "small":
        switch (color) {
          case "white":
            return ArrowRightSmallInverted;
          default:
            return ArrowRightSmall;
        }
      case "medium":
        switch (color) {
          case "white":
            return ArrowRightMediumInverted;
          default:
            return ArrowRightMedium;
        }
      case "large":
      default:
        switch (color) {
          case "white":
            return ArrowRightLargeInverted;
          default:
            return ArrowRightLarge;
        }
    }
  }
  const size = validateSize(props.size);
  const color = validateColor(props.color);
  const arrow = arrowSrc(size, color);
  const type = props.submit ? "submit" : "button";

  const classNames = ["button", size, color, props.className].filter((x) => x);

  const inner = (
    <>
      <div className="text">{props.text}</div>
      {!props.noArrow && (
        <img className="arrow" src={arrow} role="presentation" />
      )}
    </>
  );

  if (props.href && !props.onClick) {
    return (
      <Link href={props.href} className={classNames.join(" ")} {...props}>
        {inner}
      </Link>
    );
  }
  return (
    <button
      type={type}
      role="button"
      className={classNames.join(" ")}
      onClick={props.onClick}
    >
      {inner}
    </button>
  );
};
export default Button;

import React, { FunctionComponent } from "react";
import {
  Trans,
  useTranslation,
  useI18next,
  Link,
} from "gatsby-plugin-react-i18next";

type TranslationProps = {
  id: string;
};

const allowedTags = {
  sup: <sup />,
  span: <span />,
  strong: <strong />,
  abbr: <abbr />,
  b: <strong />,
  u: <u />,
  br: <br />
};
const Translation: FunctionComponent<TranslationProps> = ({ id }) => {
  return <Trans components={allowedTags}>{id}</Trans>;
};
export default Translation;
export { useTranslation, useI18next, Link };

import React, { FunctionComponent, useEffect, useState } from "react";
import scrollToElement from "../../helpers/scrollToElement";
import Scrollspy from "react-scrollspy";

function getSectionId(): string {
  return document.querySelector(".main-section")?.id || "";
}
function getChapterIds(): string[] {
  const ids: string[] = [];
  const chapters = document.querySelectorAll<HTMLElement>(".chapter");
  chapters.forEach((element: HTMLElement) => ids.push(element.id));
  return ids;
}
function formatUrl(section: string, chapter?: string): string {
  if (!chapter) {
    return "/" + section;
  }
  return "/" + [section, chapter].join("/");
}
function setUrl(url: string) {
  history.replaceState(null, "", url);
}
type ChapterTrackerProps = {
  slug?: string;
  childRoutes?: string[];
};
const ChapterTracker: FunctionComponent<ChapterTrackerProps> = ({
  children,
  slug,
  childRoutes,
}) => {

  useEffect(() => {
    if (slug) {
      window.setTimeout(() => {
        scrollToElement(slug)
      }, 100);
    }
  }, [slug])

  const updatePath = (element: HTMLElement) => {

    const section = getSectionId();
    const chapter = element.id

    setUrl(formatUrl(section, chapter));
  }


  return <Scrollspy items={childRoutes} onUpdate={updatePath}>{children}</Scrollspy>;
};

export default ChapterTracker;

import React, { FunctionComponent } from "react";
import Separator from "./Separator";
import Translation, { useI18next, useTranslation } from "../../../Translation";
import Language from "./Language";
import Actions from "../Actions";
import Link from "../../../Link";

type LanguagesProps = {
  open: boolean;
  toggle: () => void;
};
const Languages: FunctionComponent<LanguagesProps> = ({ open, toggle }) => {
  const labels: { [key: string]: string } = {
    en: "English",
    tch: "繁體中文",
    sch: "简体中文",
    vie: "TIẾNG VIỆT",
    kor: "한국어",
  };

  const { languages, originalPath } = useI18next();
  const { t } = useTranslation();

  const openClass = open ? "open" : "";
  return (
    <div className={["language-selector", openClass].join(" ")}>
      <div className="hidden laptop:block">
        <div className="container mx-auto px-4 relative">
          <div className="sticky-actions">
            <Actions languagesOpen={open} toggleLanguages={toggle} />
          </div>
        </div>
      </div>

      <div className="wrapper">
        <div className="container mx-auto p-4 pt-8 laptop:pt-2 relative">
          <div className="text-strong uppercase">
            <Translation id="choose-language" />
          </div>
          <div className="flex w-full flex-wrap items-center -ml-4 py-2">
            <Language lang="en" label={labels["en"]} to={originalPath} />
            <Separator className="hidden laptop:inline-block" />
            <Language lang="sch" label={labels["sch"]} to={originalPath} />
            <Separator className="hidden laptop:inline-block" />
            <Language lang="tch" label={labels["tch"]} to={originalPath} />
            <Separator className="hidden laptop:inline-block" />
            <Language lang="vie" label={labels["vie"]} to={originalPath} />
            <Separator className="hidden laptop:inline-block" />
            <Language lang="kor" label={labels["kor"]} to={originalPath} />
          </div>
          <hr className="my-2" />
          <div className="text-strong uppercase">
            <Translation id="get-materials" />
          </div>
          <div className="flex w-full flex-wrap items-center -ml-4 py-2">
            <Language label={t("burmese")} to="/bur/resources" />
            <Separator className="hidden laptop:inline-block" />
            <Language label={t("cambodian")} to="/khm/resources" />
            <Separator className="hidden laptop:inline-block" />
            <Language label={t("hmong")} to="/hmn/resources" />
            <Separator className="hidden laptop:inline-block" />
            <Language label={t("somali")} to="/som/resources" />
            <Separator className="hidden laptop:inline-block" />
            <Language label={t("spanish")} to="/spa/resources" />
            <Separator className="hidden laptop:inline-block" />
            <Language label={t("haitian-creole")} to="/hat/resources" />
          </div>

          <div className="absolute top-0 right-0">
            <button className="close" onClick={toggle}>
              <div className="icon"></div>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Languages;

const breakpoints = ["mobile", "tablet", "laptop", "desktop"];
const breakpointSizes = {
  mobile: 576,
  tablet: 900,
  laptop: 1200,
};

function smallerBreakpoint(breakpoint: string): string {
  return breakpoints[breakpoints.indexOf(breakpoint) - 1];
}

function minWidthQuery(size: number) {
  return `(min-width: ${size}px)`;
}
function maxWidthQuery(size: number) {
  return `(max-width: ${size}px)`;
}

export const isBreakpointOrGreater = (breakpoint?: string): boolean => {
  if (!breakpoint) {
    return true;
  }
  if (breakpoint === "never") {
    return false;
  }
  const smaller = smallerBreakpoint(breakpoint);
  if (!smaller) {
    return true;
  }
  return window.matchMedia(minWidthQuery(breakpointSizes[smaller])).matches;
};

export const isMobile = (): boolean => {
  return window.matchMedia(maxWidthQuery(breakpointSizes.mobile)).matches;
};

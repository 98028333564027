import { graphql, useStaticQuery } from "gatsby";
import React, { useEffect, useReducer } from "react";
import { isMobile } from "../helpers/breakpoints";
import Translation from "./Translation";

type GlossaryType = {
  name: string;
  top: number;
  left: number;
};
type ActionType = {
  type: string;
  payload?: GlossaryType;
};
const ACTION_OPEN = "OPEN";
const ACTION_CLOSE = "CLOSE";
const initialState: GlossaryType = { name: "", top: 0, left: 0 };
const reducer = (state: GlossaryType, action: ActionType): GlossaryType => {
  switch (action.type) {
    case ACTION_CLOSE:
      return initialState;
    case ACTION_OPEN:
      if (action.payload !== undefined) {
        return action.payload;
      }
  }
  return state;
};

const GlossaryPopup = () => {
  const data = useStaticQuery(graphql`
    query {
      close: file(base: { eq: "close.svg" }) {
        publicURL
      }
    }
  `);
  const [state, dispatch] = useReducer(reducer, initialState);

  const popup = (event: MouseEvent) => {
    const target = event.target as HTMLElement;
    //const name = target.attributes.getNamedItem("term")?.value;
    const name = target.dataset["term"];

    if (name) {
      const position = target.getBoundingClientRect();
      const top = position.top + 0;
      let left = position.left + 0;
      if (isMobile()) {
        left = 0;
      }
      dispatch({
        type: ACTION_OPEN,
        payload: { name: name, top, left },
      });
    }
  };

  useEffect(() => {
    const abbrs = document.querySelectorAll("abbr");
    abbrs.forEach((abbr) => abbr.addEventListener("click", popup));
    return () => {
      abbrs.forEach((abbr) => abbr.removeEventListener("click", popup));
    };
  });

  const hide = () => {
    dispatch({ type: ACTION_CLOSE });
  };
  const isClosed = () => {
    return state.name === "";
  };
  const className = ["fixed top-0 left-0 z-20", isClosed() && "hidden"]
    .filter((x) => x)
    .join(" ");

  const { name, top, left } = state;
  return (
    <>
      <div className={className} onClick={hide} style={{ top, left }}>
        <div className="mx-4 tablet:mx-0 bg-white tablet:w-96 p-4 shadow-xl">
          <div className="relative">
            <div className="absolute right-0">
              <img src={data.close.publicURL} className="w-8" />
            </div>
          </div>
          {name !== "" && (
            <dl className="mx-4">
              <dt className="my-2">
                <strong className="text-red">
                  <Translation id={`${name}-term`} />
                </strong>
              </dt>
              <dd className="py-2">
                <Translation id={`${name}-definition`} />
              </dd>
            </dl>
          )}
        </div>
      </div>
      <div
        className={[
          "fixed top-0 right-0 bottom-0 left-0 z-10",
          isClosed() && "hidden",
        ].join(" ")}
        onClick={hide}
      ></div>
    </>
  );
};

export default GlossaryPopup;

import React, { FunctionComponent } from "react";
import Img from "gatsby-image/withIEPolyfill";
import { graphql } from "gatsby";

type FixedImageProps = {
  fixed: any;
};
type FluidImageProps = {
  fluid: any;
};
export type CommonImageProps = {
  className?: string;
  alt?: string;
  width?: number;
  height?: number;
  imgStyle?: object;
  objectFit?: string;
};
type ImageProps = CommonImageProps & (FixedImageProps | FluidImageProps);
const Image: FunctionComponent<ImageProps> = ({ className, ...props }) => {
  const { width, height } = props;
  const styles = {
    width: "",
    height: "",
  };
  if (width) {
    styles.width = `${width}px`;
  }
  if (height) {
    styles.height = `${height}px`;
  }
  return (
    <div className={className} style={styles}>
      <Img {...props} />
    </div>
  );
};
export default Image;

export const DesktopImage = graphql`
  fragment DesktopImage on File {
    childImageSharp {
      fluid(maxWidth: 2000, quality: 100) {
        ...GatsbyImageSharpFluid_withWebp
      }
    }
  }
`;
export const MobileImage = graphql`
  fragment MobileImage on File {
    childImageSharp {
      fluid(maxWidth: 600, quality: 100) {
        ...GatsbyImageSharpFluid_withWebp
        ...GatsbyImageSharpFluidLimitPresentationSize
      }
    }
  }
`;
export const SmallImage = graphql`
  fragment SmallImage on File {
    childImageSharp {
      fluid(maxWidth: 200, quality: 100) {
        ...GatsbyImageSharpFluid_withWebp
        ...GatsbyImageSharpFluidLimitPresentationSize
      }
    }
  }
`;
export const LiverImage = graphql`
  fragment LiverImage on File {
    childImageSharp {
      fluid(maxWidth: 590, quality: 100) {
        ...GatsbyImageSharpFluid_withWebp
        ...GatsbyImageSharpFluidLimitPresentationSize
      }
    }
  }
`;
export const LiverIndicator = graphql`
  fragment LiverIndicator on File {
    childImageSharp {
      fluid(maxWidth: 140, quality: 100) {
        ...GatsbyImageSharpFluid_withWebp
        ...GatsbyImageSharpFluidLimitPresentationSize
      }
    }
  }
`;

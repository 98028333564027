import { StringStringMap } from "../types";

const languageCodeMap: StringStringMap = {
  en: "en",
  sch: "zh-Hans",
  tch: "zh-Hant",
  vie: "vi",
  kor: "ko",
};

export function standardLanguageCode(lang: string): string {
  return languageCodeMap[lang] || "en";
}
